<template>
  <div>
    <iframe
      v-resize="{ checkOrigin: false, log: !!$config.local }"
      class="ypq-site--iframe"
      frameborder="0"
      :src="url"
    />
  </div>
</template>

<script>
// sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation"
export default {
  name: 'ViewSite',
  props: {
    page: {
      type: String,
      required: true
    }
  },

  data: () => ({
    url: null
  }),

  computed: {
    lang() {
      return this.$store.getters['lang/current']
    },
    defaultLang() {
      return this.$store.getters['lang/defaultLn']
    },
    key() {
      return `${this.lang}-${this.page}`
    }
  },

  watch: {
    key: 'setUrl'
  },

  mounted() {
    this.setUrl()
  },

  methods: {

    urlExists(url) {
      return this.$http.get(url)
        .then(() => url)
        .catch(err => {
          // network error can be caused by CORS policy. 404 is when CORS
          // are ok, but page doesn't exist
          if (err.code === 'ERR_NETWORK' || err.response?.status === 404) {
            return false
          }
          // an other error occured, throw an error
          throw err
        })
    },

    async setUrl() {
      this.url = null
      if (!this.page || !this.lang) {
        return
      }
      const urls = [
        // check if wordpress page exists in current language
        `${this.$config.infoSiteUrl}/${this.lang}-${this.page}/`,
        // then default language
        `${this.$config.infoSiteUrl}/${this.defaultLang}-${this.page}/`,
        // then no language defined at all in wordpress slug
        `${this.$config.infoSiteUrl}/${this.page}/`
      ]

      return Promise.resolve()
        .then(async _ => {
          let url = await this.urlExists(urls[0])
          if (!url) {
            url = await this.urlExists(urls[1])
          }
          if (!url) {
            url = await this.urlExists(urls[2])
          }
          this.url = url
        })
        .catch(err => this.$err(err))
    }
  }
}
</script>

<style lang="scss">
.ypq-site--iframe {
  border: 0;
  width: 100%;
  height: 1000px;
}
</style>
